import { graphql } from "gatsby";
import React, { useState } from "react";
import PropTypes from "prop-types";
import SEO from "../components/SEO";
import "../styles/ia/iapage.scss";
import Slider from "../components/slider";
import { RichText } from "prismic-reactjs";
import UpcomingEvents from "../components/upcomingEvents";
import SwiperCore, { Navigation, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import linkResolver from "../utils/linkResolver";
import { css } from "@emotion/core";
import { colors } from "../styles/styles";

SwiperCore.use([Navigation, Controller]);

const IaPage = ({ data, pageContext: { locale }, location }) => {
  const isEven = (n) => {
    return n % 2 == 0;
  };

  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  const iaData = data.prismic.iapage;

  const sliderAwards =
    iaData.price_carousel &&
    iaData.price_carousel.map((item, i) => ({
      id: i,
      name: item.carousel_title && item.carousel_title[0].text,
      image: item.carousel_image && item.carousel_image,
      link: item.carousel_content && item.carousel_content,
    }));

  const upComingEvents =
    iaData.actu_carousel &&
    iaData.actu_carousel.map((item, i) => ({
      id: i,
      upcoming_event_desc: item.carousel_title && item.carousel_title,
      upcoming_event_date: item.carousel_subtitle && item.carousel_subtitle,
      upcoming_event_image: item.carousel_img && item.carousel_img,
      upcoming_event_link: "google.com",
    }));

  const itemGain = [];

  iaData.gains_fields &&
    iaData.gains_fields.map((item) => {
      itemGain.push({
        url: item.icon.url && item.icon.url,
        alt: item.icon.alt && item.icon.alt,
        title: item.description && item.description[0].text,
        subtitle:
          item.subtitle_description && item.subtitle_description[0].text,
      });
    });

  return (
    <>
      <SEO
        title={iaData.meta_title}
        desc={iaData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <section className="header-ia">
        <div className="container">
          <h4 className="title is-4 is-uppercase has-text-centered has-text-weight-semibold">
            {iaData.ia_title && iaData.ia_title[0].text}
          </h4>
          <p className="is-size-5 has-text-centered text">
            {iaData.ia_subtitle && iaData.ia_subtitle[0].text}
          </p>
          <img
            className="landing-img"
            src={iaData.ia_header_image && iaData.ia_header_image.url}
            alt={iaData.ia_header_image && iaData.ia_header_image.alt}
          />
          <div>
            <a
              href={
                iaData.ia_button_demo_link && iaData.ia_button_demo_link.url
              }
              target="_blank"
              className="button is-rounded button__primary has-text-white mr-3 mb-3"
            >
              {iaData.ia_button_demo && iaData.ia_button_demo[0].text}
            </a>
            {/* <a href={iaData.ia_button_freetrial_link && iaData.ia_button_freetrial_link.url} target="_blank" id="trial-bt" className="button is-rounded is-outlined is-link">{iaData.ia_button_freetrial && iaData.ia_button_freetrial[0].text}</a> */}
          </div>
        </div>
      </section>
      <section className="section-ia">
        <div className="container-ia">
          {iaData.body[0].fields &&
            iaData.body[0].fields.map((field, i) => (
              <div
                key={i}
                className={`columns ia-item ${!isEven(i) ? null : `blue-bg`} ${
                  field.isright ? `ia-right` : null
                }`}
              >
                <div className="column is-three-fifths img-carousel-container">
                  <img
                    src={field.sectionimage.url && field.sectionimage.url}
                    alt={field.sectionimage.alt && field.sectionimage.alt}
                    className="img-carousel"
                  />
                </div>
                <div className="column fils ia-text">
                  <h1 className="title is-5 is-size-6-mobile has-text-centered has-text-weight-semibold">
                    {field.sectiontitle && field.sectiontitle[0].text}
                  </h1>
                  <p className="second-paragraphe">
                    {field.sectionsubtitle && field.sectionsubtitle[0].text}
                  </p>
                  {field.isbutton && (
                    <a
                      href={field.sectionbuttonurl}
                      target="_blank"
                      id="trial-bt"
                      className="button is-rounded button__primary has-text-white mr-3 mb-3 carousel-btn"
                    >
                      {field.sectionbuttontext[0].text}
                    </a>
                  )}
                </div>
              </div>
            ))}
        </div>
      </section>
      <section style={{backgroundColor: "#ffffff"}} className="section-price">
        <div className="container">
          <h3 className="title is-3 has-text-centered">
            {iaData.price_title && RichText.asText(iaData.price_title)}
          </h3>
          <div className="columns is-centered">
            <div className="column is-12 awards-slider">
              {sliderAwards && (
                <Slider
                  className="swiper-award"
                  slidesList={sliderAwards}
                  layout="titleFirst"
                  sliderId="awards"
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <div style={{backgroundColor: "#f0f5ff"}} css={gainsCss}>
        <section className={`section-gain`}>
          <div className="container">
            <div className="columns container-bloc">
              <div className="column gain-container1">
                <p className="mainTitle is-size-3 titre">
                  {iaData.gain_title && iaData.gain_title[0].text}
                </p>
                <p className="mainSubtitle is-size-6 has-text-weight-light sous-titre">
                  {iaData.gains_subtitle && iaData.gains_subtitle[0].text}
                </p>
                <a
                  href={iaData.gains_demo_link && iaData.gains_demo_link.url}
                  target="_blank"
                  id="trial-bt"
                  className="button is-rounded button__primary has-text-white mr-3 mb-3"
                >
                  {iaData.gains_demo && iaData.gains_demo[0].text}
                </a>
              </div>
              <div className="column container2">
                <img
                  src={iaData.gains_image && iaData.gains_image.url}
                  alt={iaData.gains_image && iaData.gains_image.alt}
                />
                <div className="container_gain">
                  {itemGain &&
                    itemGain.map((item, i) => {
                      return (
                        <div className={`item${i}`}>
                          <img
                            src={item.url && item.url}
                            alt={item.alt && item.alt}
                          />
                          <div className="content_gain">
                            <h4 className="title_gain">
                              {item.title && item.title}
                            </h4>
                            <span className="subtitle_gain">
                              {item.subtitle && item.subtitle}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section style={{ background: "#ffffff", padding: "2rem" }}>
        <div className="container">
          <h1 className="title is-3 has-text-centered">
            {iaData.actu_title && iaData.actu_title[0].text}
          </h1>
          <p className="is-6 has-text-centered">
            {iaData.actu_subtitle && iaData.actu_subtitle[0].text}
          </p>
          <UpcomingEvents
            upcomingEventsTitle={iaData.actu_title && iaData.actu_title[0].text}
            upcomingEvents={upComingEvents && upComingEvents}
          />
        </div>
      </section>
      <div css={feedBackCss} style={{ marginTop: "100px" }}>
        <section style={{backgroundColor: "#f0f5ff"}} className="section is-medium feedback-section">
          <div className="container">
            <h3 className="title is-3 has-text-centered has-text-weight-semibold pb-4 title-feed">
              {RichText.asText(iaData.verbatim_title && iaData.verbatim_title)}
            </h3>
            <h6 className="subtitle is-5 has-text-centered has-text-weight-light">
              {RichText.asText(
                iaData.verbatim_subtitle && iaData.verbatim_subtitle
              )}
            </h6>
            <div className="columns is-centered">
              <div className="column is-9">
                <nav className="py-5">
                  <div className="has-text-centered">
                    <Swiper
                      centeredSlides={true}
                      loop={true}
                      loopedSlides={1}
                      onSwiper={setFirstSwiper}
                      slideToClickedSlide={true}
                      controller={{ control: secondSwiper }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                      className="swiper-feedback-controller"
                    >
                      {iaData.body2 &&
                        iaData.body2[0].fields.map((fb, i) => {
                          return (
                            <SwiperSlide key={i}>
                              {fb.carousel_img && (
                                <img
                                  src={fb.carousel_img.url}
                                  style={{ height: `150px`, width: `150px` }}
                                />
                              )}
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </div>
                </nav>
              </div>
            </div>
            <div className="columns is-centered is-multiline">
              <Swiper
                onSwiper={setSecondSwiper}
                controller={{ control: firstSwiper }}
                loop={true}
                loopedSlides={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                className="swiper-feedback"
              >
                {iaData.body2 &&
                  iaData.body2[0].fields.map((fb, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div className="column box is-7-desktop is-10-touch has-text-centered has-text-weight-medium px-5 py-5 speech-bubble">
                          <h6 className="title is-4 has-text-weight-medium">
                            {RichText.asText(fb.carousel_title)}
                          </h6>
                          <div className="is-size-5 has-text-weight-light speech-bubble-text">
                            {fb.carousel_subtitle &&
                              RichText.render(
                                fb.carousel_subtitle,
                                linkResolver
                              )}
                          </div>
                          <div className="dash-spaced mt-6">&#8203;</div>
                          <div className="has-text-weight-semibold is-size-5">
                            {fb.client_name && fb.client_name[0].text}
                          </div>
                          <div className="has-text-weight-light is-size-6">
                            {fb.client_name && fb.client_role[0].text}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                <div
                  role="button"
                  name="Previous slide"
                  aria-label="Previous slide"
                  className="swiper-button-prev"
                ></div>
                <div
                  role="button"
                  name="Next slide"
                  aria-label="Next slide"
                  className="swiper-button-next"
                ></div>
              </Swiper>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default IaPage;

IaPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
};

export const iaQuery = graphql`
  query IaQuery($uid: String!, $locale: String!) {
    prismic {
      iapage(uid: $uid, lang: $locale) {
        body2 {
          ... on PRISMIC_IapageBody2Verbatim {
            type
            label
            fields {
              carousel_img
              carousel_title
              client_role
              client_name
              carousel_subtitle
            }
            primary {
              verbatim_subtitle
              verbatim_title
            }
          }
        }
        actu_carousel {
          carousel_img
          carousel_subtitle
          carousel_title
        }
        actu_subtitle
        actu_title
        body {
          ... on PRISMIC_IapageBodyTextblock {
            type
            label
            fields {
              isbutton
              isimage
              isright
              sectionbuttontext
              sectionimage
              sectionsubtitle
              sectiontitle
            }
          }
        }
        gain_title
        gains_demo
        gains_demo_link {
          ... on PRISMIC__ExternalLink {
            target
            _linkType
            url
          }
        }
        gains_image
        gains_subtitle
        gains_fields {
          description
          icon
          subtitle_description
        }
        ia_button_demo
        ia_button_demo_link {
          ... on PRISMIC__ExternalLink {
            url
            target
          }
        }
        ia_button_freetrial
        ia_button_freetrial_link {
          ... on PRISMIC__ExternalLink {
            target
            url
          }
        }
        ia_header_image
        ia_subtitle
        ia_title
        interview_img
        interview_text
        meta_description
        interview_title
        interview_subtitle
        meta_title
        price_carousel {
          carousel_content
          carousel_image
          carousel_title
        }
        price_subtitle
        price_title
      }
    }
    site {
      ...SiteInformation
    }
  }
`;

const feedBackCss = css`
  .feedback-section {
    margin-top: -5rem;
  }
  .box {
    border-radius: 12px;
    box-shadow: 0 19px 7px 0 #c5ccde;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: #fff;
      border-top: 0;
      margin-left: -20px;
      margin-top: -20px;
    }
  }

  .swiper-feedback-controller .gatsby-image-wrapper {
    display: flex;
    align-items: center;
  }
  .dash-spaced {
    background-image: linear-gradient(
      to right,
      ${colors.lightgray} 30%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    width: 200px;
    margin: 0 auto;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    .gatsby-image-wrapper {
      height: 80px;
      width: 200px;
      img {
        max-height: 80px;
      }
    }
  }
  .title-feed {
    margin-top: -9%;
  }
  .swiper-feedback {
    padding-top: 40px;
    padding-bottom: 40px;

    .speech-bubble-text {
      color: ${colors.blueLightSeven};
    }
  }

  @media (max-width: 767px) {
    .swiper-button-prev,
    .swiper-button-next {
      background-color: transparent;
    }
  }

  @media (min-width: 1024px) {
    .swiper-feedback .swiper-slide.swiper-slide-next {
      overflow: visible;
      right: 33%;
      opacity: 30%;
    }

    .swiper-feedback .swiper-slide.swiper-slide-prev {
      overflow: visible;
      left: 33%;
      opacity: 30%;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 12%;
      right: auto;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 12%;
      left: auto;
    }
  }
`;

const gainsCss = css`
  .section-gain .content {
    position: absolute;
    top: 555px;
    width: 20%;
    left: 75%;
  }
  .section-gain .item0,
  .item1,
  .item2,
  .item3,
  .item4 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    column-gap: 4.4%;
  }
  .section-gain .container2 {
    position: relative;
  }
  .container_gain {
    position: absolute;
    left: 47%;
    top: 2.2%;
    display: grid;
    grid-template-columns: 8.7% 31.78% 8.7%;
    grid-template-rows: repeat(5, 15.66%);
    gap: 3.65% 0px;
    grid-template-areas:
      ". item0 item0"
      "item1 item1 ."
      ". item2 item2"
      "item3 item3 ."
      ". item4 item4";
    width: 100%;
    height: 100%;
  }
  .item0 {
    grid-area: item0;
  }
  .item1 {
    grid-area: item1;
  }
  .item2 {
    grid-area: item2;
  }
  .item3 {
    grid-area: item3;
  }
  .item4 {
    grid-area: item4;
  }

  .container_gain .content_gain {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .container_gain img {
    width: 35%;
  }

  .container_gain .content_gain .subtitle_gain {
    font-size: 88%;
    font-weight: normal;
  }

  .container_gain .content_gain .title_gain {
    font-size: 150%;
    font-weight: 500;
  }

  @media only screen and (max-width: 1392px) {
    .container_gain img {
      width: 50px;
    }

    .container_gain .content_gain .subtitle_gain {
      font-size: 14px;
    }

    .container_gain .content_gain .title_gain {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: 1216px) {
    .container_gain img {
      width: 43px;
    }

    .container_gain .content_gain .subtitle_gain {
      font-size: 11px;
    }

    .container_gain .content_gain .title_gain {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 898px) {
    .container_gain img {
      width: 43px;
    }
    .container_gain .content_gain .subtitle_gain {
      font-size: 9px;
    }
    .container_gain .content_gain .title_gain {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    .container_gain img {
      width: 80px;
    }
    .container_gain .content_gain .subtitle_gain {
      font-size: 13px;
    }
    .container_gain .content_gain .title_gain {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 560px) {
    .container_gain img {
      width: 55px;
    }
    .container_gain .content_gain .subtitle_gain {
      font-size: 13px;
    }
    .container_gain .content_gain .title_gain {
      font-size: 21px;
    }
  }
  @media only screen and (max-width: 465px) {
    .container_gain img {
      width: 50px;
    }
    .container_gain .content_gain .subtitle_gain {
      font-size: 10px;
    }
    .container_gain .content_gain .title_gain {
      font-size: 21px;
    }
  }
  @media only screen and (max-width: 376px) {
    .container_gain img {
      width: 35px;
    }
    .container_gain .content_gain .subtitle_gain {
      font-size: 10px;
    }
    .container_gain .content_gain .title_gain {
      font-size: 14px;
    }
  }
`;
