import React from "react";
import Img from "gatsby-image";

const Image = ({ sharp, image, style = {} }) => {
  sharp = undefined; // To prevent 404 on image sharp and have blur image with data: (netlify-site-cache)

  if (sharp && !sharp.publicURL)
    console.log(
      "Caution: This image is imported directly from prismic: ",
      image.alt,
      image
    );
  
  const alt = image.alt ? image.alt : "";

  if ((!sharp || !sharp.publicURL) && image) {
    // avoid: displaying image from prismic
    return (
      <div className="gatsby-image-wrapper">
        <img src={image.url} alt={image.alt} loading="lazy" decoding="async" />
      </div>
    );
  } else if (sharp && sharp.childImageSharp === null && sharp.publicURL) {
    // gatsby image not available,using publicURL (get image from local)  case for svg, gif...
    return (
      <div className="gatsby-image-wrapper">
        <img
          src={sharp.publicURL}
          alt={image.alt}
          loading="lazy"
          decoding="async"
          id={
            alt == "Google Play" || alt === "Google Play App"
              ? "googleplay-bt"
              : alt == "App Store App" || alt == "AppStore"
              ? "appstore-bt"
              : ""
          }
        />
      </div>
    );
  } else if (sharp && sharp.childImageSharp !== null) {
    // use gatsby image childImageSharp.fluid to display image
    return (
      <Img
        fluid={sharp.childImageSharp.fluid}
        alt={image.alt}
        imgStyle={{ objectFit: "contain", ...style }}
        loading="lazy"
        decoding="async"
      />
    );
  } else {
    return "";
  }
};

export default Image;
